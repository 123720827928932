.nfo-list {
  h2 {
    margin: 0 !important;
    padding: 0 !important;
    padding-left: 5px !important;
  }
  h6 {
    padding: 5px !important;
    margin: 0 !important;
  }
  a {
    text-decoration: none;
  }
}

.nfo-card {
  margin: 5px 5px;
  border-radius: 10px !important;
  h4 {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    color: var(--ion-color-dark);
    margin: 0 !important;
    margin-top: 5px !important;
    padding-left: 10px !important;
  }
  p {
    padding-left: 10px !important;
    margin: 0 !important;
    color: var(--ion-color-medium) !important;
  }
  .amc-logo {
    width: 64px;
    height: 64px;
    border-radius: 100%;
    border: 1px solid var(--ion-color-primary);
    overflow: hidden;
    img {
      min-width: 48px !important;
      max-width: 48px !important;
      min-height: 48px !important;
      max-height: 48px !important;
      padding-left: 12px !important;
      padding-top: 12px !important;
    }
  }
  ion-button {
    position: absolute;
    font-weight: bold;
    right: 0px !important;
    margin-top: 15px !important;
  }
}

.nfo-detail-card {
  margin: 15px 5px;
  border-radius: 10px !important;
  h4 {
    font-weight: bold;
    margin: 5px 0 !important;
    padding: 0 !important;
    color: var(--ion-color-dark);
  }
  h6 {
    font-weight: bold;
    margin: 0 !important;
    margin-bottom: 20px !important;
    padding: 0 !important;
  }
  p {
    margin: 0 !important;
    padding: 0 !important;
    margin-top: 20px !important;
    font-size: 1rem;
  }
}

@media screen and (max-width: 1200px) {
  .nfo-card {
    h4 {
      padding-left: 30px !important;
    }
    p {
      padding-left: 30px !important;
    }
  }
}

@media screen and (max-width: 992px) {
  .nfo-card {
    h4 {
      padding-left: 30px !important;
    }
    p {
      padding-left: 30px !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .nfo-card {
    padding: 1rem !important;
    h4 {
      font-size: 1rem !important;
    }
    p {
      font-size: 0.9rem !important;
    }

    .amc-logo {
      width: 48px !important;
      height: 48px !important;
      img {
        min-width: 42px !important;
        max-width: 42px !important;
        min-height: 42px !important;
        max-height: 42px !important;
        padding-left: 6px !important;
        padding-top: 6px !important;
      }
    }
  }
}

@media screen and (max-width: 576px) {

  .nfo-list {
    h2 {
      font-size: 1.1rem;
    }
    h6{
      font-size: 0.8rem;
    }
  }

  .nfo-card {
    h4 {
      margin: 0 !important;
      font-size: 0.9rem !important;
    }
    p {
      font-size: 0.8rem !important;
    }
    .amc-logo {
      width: 42px !important;
      height: 42px !important;
      img {
        min-width: 32px !important;
        max-width: 32px !important;
        min-height: 32px !important;
        max-height: 32px !important;
        padding-left: 8px !important;
        padding-top: 8px !important;
      }
    }
  }
  .nfo-detail-card{
    h6{
      font-size: 0.8rem !important;
    }
  }
}
