.hero-section {
  color: var(--ion-color-dark);
  background-color: var(--ion-color-white);
  h1 {
    margin: 0;
    font-weight: bold;
  }
  p {
    margin: 0;
    margin-left: 5px;
  }
  ion-button {
    margin-top: 1rem;
    --border-radius: 50px;
    font-weight: bold;
  }
}
ion-card {
  margin: 5px;
  padding: 1.5rem;
  box-shadow: rgba(95, 71, 71, 0.2) -3px 2px 33px -14px;
}

.holding-pattern {
  margin-top: 25px;
  border-radius: 10px;
  .asset-icon {
    border-radius: 100%;
    overflow: hidden;
    width: 64px;
    height: 64px;
    .asset-img {
      width: 32px;
      height: 32px;
      margin-left: 16px;
      margin-top: 16px;
    }
  }

  .primary-icon {
    background-color: #cce5d3;
  }
  .danger-icon {
    background-color: #f4cbce;
  }
  .warning-icon {
    background-color: #fceec7;
  }

  p {
    margin: 0;
    font-weight: bold;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  h4 {
    margin: 5px 0;
    font-weight: bold;
    padding-left: 20px !important;
    padding-right: 20px !important;
    color: var(--ion-color-dark);
  }
  h6 {
    font-weight: bold;
    padding: 0;
    color: var(--ion-color-dark);
    position: absolute;
    bottom: 2px !important;
    right: 2px !important;
  }
  .weightage {
    margin-left: 20px !important;
  }
}

/* media queries */
@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 992px) {
  .holding-pattern {
    h4 {
      padding-left: 30px !important;
    }
    p {
      padding-left: 30px !important;
    }
    .weightage {
      margin-left: 30px !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .hero-section {
    h1 {
      font-size: 1.5rem !important;
    }

    ion-button {
      margin-top: 0.75rem;
    }
  }
}
@media screen and (max-width: 576px) {
  .hero-section {
    h1 {
      font-size: 1.4rem !important;
    }
    h6 {
      margin-left: px !important;
      font-size: 0.9rem !important;
    }
    ion-button {
      margin-top: 0.75rem;
    }
  }
  .holding-pattern {
    h4 {
      padding-right: 5px !important;
    }
    h6 {
      margin: 0 !important;
      font-size: 0.8rem !important;
    }
    p {
      font-size: 0.8rem !important;
    }
    .asset-icon {
      border-radius: 100%;
      overflow: hidden;
      width: 48px;
      height: 48px;
      .asset-img {
        width: 26px;
        height: 26px;
        margin-left: 12px;
        margin-top: 12px;
      }
    }
  }
}
@media screen and (max-width: 340px) {
  .hero-section {
    h1 {
      font-size: 1rem !important;
      padding-top: 5px;
    }
    h6 {
      margin: 0;
      font-size: 0.75rem !important;
      margin-left: 2px;
    }
  }
}
