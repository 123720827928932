body {
  background-color: #f2f2f2;
}
h1,
h2,
h3 {
  font-weight: bold;
}
ion-grid {
  padding: 5%;
}
ion-segment {
  --background: var(--ion-color-dark);
}
ion-segment-button {
  --color: var(--ion-color-white);
  --color-checked: var(--ion-color-dark);
}
ion-toast {
  --box-shadow: none;
}
ion-toolbar {
  padding: 0.5% 2%;
}
ion-button {
  font-weight: bold !important;
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0;
}
.hr-line {
  border-bottom: 1px solid #f1f1f1;
  margin: 0.5rem 0;
}
.text-s {
  font-size: 90%;
}
.text-xs {
  font-size: 80%;
}
.text-xxs {
  font-size: 70%;
}
.disable-scrollbar {
  --offset-bottom: auto !important;
  --overflow: hidden;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
.branded-toolbar {
  --background: var(--ion-color-primary);
  --padding: 0.5% 2%;
  color: var(--ion-color-white);
}

