.transaction-section-header {
  h2{
    margin: 2px 5px !important;
    padding: 0;
  }
  p {
    margin: 0;
    padding-left: 5px;
    color: var(--ion-color-medium);
  }
}
.transaction-card {
  border-radius: 10px;
  h3 {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 !important;
    padding: 0 !important;
    color: var(--ion-color-dark);
  }
  p {
    margin: 0 !important;
  }
  .amc-logo {
    width: 64px;
    height: 64px;
    border-radius: 100%;
    overflow: hidden;
    img {
      min-width: 56px !important;
      max-width: 56px !important;
      min-height: 56px !important;
      max-height: 56px !important;
      padding-left: 8px !important;
      padding-top: 8px !important;
    }
  }
}
.transaction-entry {
  margin: 20px 0;
  a {
    text-decoration: none;
    color: var(--ion-color-medium);
  }
}
.date-range {
  border-radius: 5px;
  margin: 5px;
  padding: 0.2rem 0.5rem;
  color: var(--ion-color-white);
  background-color: var(--ion-color-primary);
}
.date-picker {
  .modal-wrapper {
    --max-width: 300px;
    --max-height: 500px;
    border-radius: 5px;
  }
}
.calendar-header {
  color: var(--ion-color-white);
  background-color: var(--ion-color-primary);
  padding: 1%;
  p {
    margin: 0;
  }
  h5 {
    margin: 0;
  }
}

.trxn-wrapper {
  margin: 5px;
  padding: 12px;
}
.trxn-status {
  padding: 5px 10px;
  height: 25px;
  margin-left: 10px;
  color: #666666;
  background-color: #eeeeee;
}
.trxn-badge {
  background: var(--ion-color-primary);
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  color: #ffffff;
}
.app-empty-transactions {
  text-align: center;
  img {
    margin: 0 auto;
    width: 128px;
    height: auto;
    margin-top: 5rem;
  }
}

@media screen and (max-width: 992px) {
  .transaction-card {
    h3,
    p {
      padding-left: 10px !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .transaction-card {
    h3 {
      font-size: 1rem !important;
    }
    h3,
    p {
      padding-left: 0px !important;
    }
    .amc-logo {
      width: 48px;
      height: 48px;
      border-radius: 100%;
      margin-left: -10px;
      overflow: hidden;
      img {
        min-width: 42px !important;
        max-width: 42px !important;
        min-height: 42px !important;
        max-height: 42px !important;
        padding-left: 4px !important;
        padding-top: 4px !important;
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .transaction-card {
    h3,
    p {
      padding-left: 5px !important;
    }
    .amc-logo {
      margin-left: -20px;
    }
  }
}
