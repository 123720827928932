.section-header {
  margin: 20px 0px;
  h2 {
    margin: 0;
    padding-left: 5px;
  }
  p {
    margin: 0px 0px 0px 5px;
    padding: 0;
  }
}
@media screen and (max-width: 576px) {
  .section-header {
    h2 {
      font-size: 1.2rem;
    }
  }
}
