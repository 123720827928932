.page-header {
  color: var(--ion-color-dark);
  background-color: var(--ion-color-white);
  h2 {
    margin: 0;
    font-weight: bold;
  }
  h6 {
    margin: 0;
  }

  ion-button {
    margin-top: 1rem;
    --border-radius: 50px;
    font-weight: bold;
  }
}

.holding-entry {
  color: var(--ion-color-dark);
}
.holding-title {
  color: var(--ion-color-white);
  background-color: var(--ion-color-primary);
  padding: 0.2rem 1rem;
  text-align: center;
}
.investment-details-card {
  border-radius: 10px;
  h6{
      font-weight: bold;
      margin: 0;
      padding: 0;
  }
}

@media screen and (max-width: 576px) {
  .investment-details-card {
      h6{
          font-size: 0.9rem;
      }
    }
}
@media screen and (max-width: 411px) {
  .investment-details-card {
      h6{
          font-size: 0.8rem;
      }
    }
}