.holdings-card {
    border-radius: 10px;
    margin: 10px 5px;
    padding: 1.5rem;
    color: var(--ion-color-dark);
    h3{
      margin: 0;
      padding: 0;
      width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    h4{
      margin: 0;
      font-weight: bold;
    }
    h5{
      font-weight: bold;
      font-size: 0.9rem;
      margin: 0;
      color: var(--ion-color-medium);
    }
    p {
      margin: 0;
      color: var(--ion-color-dark);
    }
    .amc-logo {
      width: 48px;
      height: 48px;
      border-radius: 100%;
      overflow: hidden;
      img {
        min-width: 42px !important;
        max-width: 42px !important;
        min-height: 42px !important;
        max-height: 42px !important;
        padding-left: 6px !important;
        padding-top: 6px !important;
      }
    }
  }
  .holdings-card-link {
    text-decoration: none;
    color: var(--ion-color-medium);
  }
  
  @media screen and (max-width: 768px) {
    .holdings-card {
      h3,p{
      margin-left: 20px;;
      }
    }
  }
  @media screen and (max-width: 576px) {
    .holdings-card {
    h3{
    font-size: 1rem;
    }
    .amc-logo {
      width: 42px;
      height: 42px;
      border-radius: 100%;
      overflow: hidden;
      img {
        min-width: 36px !important;
        max-width: 36px !important;
        min-height: 36px !important;
        max-height: 36px !important;
        padding-left: 2px !important;
        padding-top: 2px !important;
      }
    }
  }
  }