h1 {
    font-size: 2rem !important;
    font-weight: bolder !important;
    margin: 0 !important;
  }
  p {
    margin: 10px;
    padding: 0;
  }
  .web-wrapper {
    color: #ffffff;
    padding: 5rem;
    min-height: 768px;
    background: #2BAE66;
  }
  .web-content-footer {
    font-size: 90%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2rem 5rem;
  }
  .grid-max-height {
    height: 100%;
    display: flex;
    flex-flow: column;
  }
  .row-max-height {
    flex-grow: 1;
  }
  
  .reset-wrapper {
    width: 75%;
    margin: 0 auto;
    a{
      text-align: center;
      color:var(--ion-color-dark);
    }
    
  }
  .reset-header {
    text-align: center;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .kashly-logo {
    overflow: hidden;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  #reset-button {
    margin: 20px 0;
  }
  
  ion-button {
    --box-shadow: none;
    text-transform: capitalize;
    letter-spacing: normal;
  }
  ion-input {
    border: 1px solid #999999;
    border-radius: 5px;
    margin-top: 10px;
    --padding-start: 20px !important;
  }
  @media screen and (max-width: 576px) {
    .reset-wrapper {
      width: 90%;
    }
  }