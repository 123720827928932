.dashboard-summary {
  margin-top: 25px;
  border-radius: 10px;
  color: var(--ion-color-dark);
  h2 {
    margin: 0 !important;
    padding: 0 !important;
    font-weight: bold;
    margin-bottom: 15px !important;
  }
  h4 {
    margin: 0;
    font-weight: bold;
  }
  h5 {
    font-weight: bold;
    font-size: 0.9rem;
    margin: 0;
    color: var(--ion-color-medium);
  }
  p {
    margin: 0 !important;
  }
}

.summary-card-secondary {
  border-radius: 10px;
  color: var(--ion-color-dark);
  margin: 5px;
  background-color: var(--ion-color-white);
  box-shadow: -3px 2px 33px -14px #ccc;

  .card-body {
    padding: 1rem 1.5rem;
  }

  .card-footer {
    padding: 0.5rem 1.5rem;
    background-color: #cbd5e1;
    color: #0f172a;
    border-radius: 0px 0px 10px 10px;
    p{
      color: #000000;
    }
  }
  .vr-line {
    width: 1px;
    height: 100%;
    border-right: 1px solid #166534;
    opacity: 0.2;
    margin: 0 auto;
  }
  h2 {
    margin: 0 !important;
    padding: 0 !important;
    font-weight: bold;
  }
  h4 {
    margin: 0;
    font-weight: bold;
    color: #000000;
  }
  
  p {
    margin: 0 !important;
  }
}

@media screen and (max-width: 1080px) {
  .dashboard-summary {
    .h4 {
      font-size: 1.2rem;
    }
  }
}
@media screen and (max-width: 992px) {
  .dashboard-summary {
    h2 {
      font-size: 1.3rem;
    }
    h4 {
      font-size: 1.1rem;
    }
  }
}
@media screen and (max-width: 576px) {
  .dashboard-summary {
    h4 {
      font-size: 1rem;
    }
  }
}
