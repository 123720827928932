.page-header {
    color: var(--ion-color-dark);
    background-color: var(--ion-color-white);
    h2 {
      margin: 0;
      font-weight: bold;
    }
    h6 {
      margin: 0;
    }
    ion-button {
      margin-top: 1rem;
      --border-radius: 50px;
      font-weight: bold;
    }
  }
  
  .transaction-entry {
    color: var(--ion-color-dark);
  }

  .transaction-details-card {
    border-radius: 10px;
    h6{
        font-weight: bold;
        margin: 0;
        padding: 0;
    }
  }
  
  @media screen and (max-width: 576px) {
    .transaction-details-card {
        h6{
            font-size: 0.9rem;
        }
      }
  }
  @media screen and (max-width: 411px) {
    .transaction-details-card {
        h6{
            font-size: 0.8rem;
        }
      }
  }