.menu-header {
  text-align: center;
  margin-top: 5rem;
  h5 {
    color: var(--ion-color-white);
    padding: 0;
    margin: 0;
    margin-top: 20px;
  }
  p {
    padding: 0;
    margin: 0;
    font-size: 0.9rem;
    color: var(--ion-color-white);
    opacity: 0.6;
  }
  .menu-avatar {
    height: 64px;
    width: 64px;
    margin: 0 auto;
    border-radius: 100%;
    overflow: hidden;
  }
}

.split-pane-visible > .split-pane-side {
  min-width: 20% !important;
  max-width: 20% !important;
}

ion-menu ion-content {
  --background: var(--ion-color-dark);
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  margin-top: 30px;
  padding: 20px 0;
  background-color: var(--ion-color-dark);
}


ion-menu.md ion-item {
  --padding-start: 20px;
  --padding-end: 20px;
  border-radius: 10px;
  --color:var(--ion-color-white);
  --background: var(--ion-color-dark);
}

ion-menu.md ion-item.selected {
  --background: var(--ion-color-medium);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-white);
}

ion-menu.md ion-item ion-icon {
  color: var(--ion-color-white);
}

ion-menu.md ion-item ion-label {
  font-weight: 400;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  margin-top: 30px;
  padding: 20px 0 0 0;
}

ion-menu.ios ion-item {
  --padding-start: 20px;
  --padding-end: 20px;
  --min-height: 50px;
  --background: var(--ion-color-dark);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: var(--ion-color-white);
}

ion-menu.ios ion-item .selected ion-icon {
  color: var(--ion-color-white);
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-item.selected {
  --color: var(--ion-color-white);
}
