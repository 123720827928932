.account-header {
  color: var(--ion-color-dark);
  background-color: var(--ion-color-white);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  text-align: center;
  h1 {
    margin: 0;
    font-weight: bold;
  }
  h6 {
    margin: 0;
    margin-left: 5px;
  }
  .account-avatar {
    height: 100px !important;
    width: 100px !important;
    margin: 0 auto;
    position: relative;
    margin-bottom: 15px;
    border-radius: 100%;
    overflow: hidden;
  }
  .avatar-edit {
    width: 100px;
    height: 25px;
    position: absolute;
    text-align: center;
    top: 75px;
    padding: 0.3rem 0;
    opacity: 0.8;
    z-index: 9999;
    background: var(--ion-color-primary);
  }
}
ion-card {
  margin: 5px;
  padding: 1.5rem;
  box-shadow: rgba(95, 71, 71, 0.2) -3px 2px 33px -14px;
}



.image-upload-modal {
  .modal-wrapper {
    --max-width: 320px;
    --max-height: 400px;
    border-radius: 5px;
  }
}
.image-upload-container {
  text-align: center;
  margin:20px;
  margin-top:50px;
}

.image-crop-container {
  text-align: center;
}
.image-cropper {
  width: 96%;
  margin: 2%;
  height: 250px;
  position: relative;
}
.zoom-slider
{
  width: 60%;
  margin: 0 auto;
}
.current-avatar {
  height: 100px !important;
  width: 100px !important;
  margin: 0 auto;
  margin-top: 50px;
  border-radius: 100%;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .account-header {
    h1 {
      font-size: 1.5rem !important;
    }
    .account-avatar {
      height: 64px !important;
      width: 64px !important;
    }
    .avatar-edit {
      width: 64px;
      height: 25px;
      top: 40px;
    }
  }
}

@media screen and (max-width: 576px) {
  .account-header {
    h1 {
      font-size: 1.4rem !important;
    }
  }
}

@media screen and (max-width: 340px) {
  .account-header {
    h1 {
      font-size: 1rem !important;
    }
  }
}
